import { useTheme } from '@emotion/react';
import { useFullstoryElement } from '@hooks/useFullstory';
import { FullStoryElements, FullStoryElementType } from '@utils/fullstory';
import { useWorkflow } from '@utils/fullstory/context';
import { pickBy } from 'lodash-es';
import { FC, Fragment, useMemo, useRef } from 'react';
import TextAreaAutoSizer from 'react-textarea-autosize';
import {
  SupportedElementWithDebounce,
  useDebouncedOnChange,
} from '../../hooks/useDebouncedOnChange';

interface RowProps {
  minRows?: number;
  maxRows?: number;
  rows?: number;
  resize?: boolean;
  maxLengthHelper?: number;
  fsName?: string;
  fsParent?: string;
  fsType?: string;
  fsElement?: FullStoryElementType;
}

export type Props = RowProps &
  SupportedElementWithDebounce<HTMLTextAreaElement>;

function getMinMaxRows({ minRows, maxRows, rows }: RowProps): {
  minRows?: number;
  maxRows?: number;
  rows?: number;
} {
  const shouldGrow = Boolean(minRows || maxRows);
  if (!shouldGrow) {
    return pickBy({
      rows,
    });
  }
  return {
    minRows: Math.min(rows || Infinity, minRows || Infinity) || maxRows || 1,
    maxRows,
  };
}

const Wrapper: FC<{ useWrapper: boolean }> = (props) => {
  if (props.useWrapper) {
    return <div css={{ position: 'relative' }}>{props.children}</div>;
  }
  return <Fragment>{props.children}</Fragment>;
};

export const TextArea: FC<Props> = (props) => {
  const ref = useRef(null);
  const { colors } = useTheme();
  const { val, onChange } = useDebouncedOnChange<HTMLTextAreaElement>({
    ...props,
    ref,
  });
  const {
    minRows,
    maxRows,
    rows,
    resize = true,
    maxLengthHelper,
    fsName,
    fsParent,
    fsType = 'textarea',
    fsElement = FullStoryElements.FIELD_INPUT,
    ...rest
  } = props;
  let Element: anyOk = 'textarea';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sizeObj = useMemo(() => getMinMaxRows({ minRows, maxRows, rows }), []);
  const { getFsComponentProps } = useFullstoryElement();
  const { id } = useWorkflow();

  if (sizeObj.minRows || sizeObj.maxRows) {
    Element = TextAreaAutoSizer;
  }

  const valLength = (val as string)?.length ?? 0;
  const hasMaxLengthHelper = Boolean(maxLengthHelper);
  const overLimit = valLength - (maxLengthHelper || 0);

  return (
    <Wrapper useWrapper={hasMaxLengthHelper}>
      <Element
        css={{
          height: 'auto',
          width: '100%',
          minHeight: 34,
          '&:disabled': {
            cursor: 'not-allowed',
            opacity: 0.6,
          },
          resize: resize ? 'initial' : 'none',
          paddingBottom: hasMaxLengthHelper ? 40 : undefined,
        }}
        {...(rest as anyOk)}
        {...sizeObj}
        {...getFsComponentProps({
          name: fsName ?? rest?.name,
          parent: fsParent ?? id,
          element: fsElement,
          type: fsType,
        })}
        onChange={onChange}
        value={val as string}
      />
      {maxLengthHelper && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            right: 0,
            fontSize: '12px',
            color: overLimit > 0 ? colors.error : colors.textMuted,
            cursor: 'default',
            padding: '5px 4px 0 0',
          }}
          title={
            overLimit > 0
              ? `You are ${overLimit} characters over the limit. Please remove some text.`
              : `You have used ${valLength} character${
                  valLength === 1 ? '' : 's'
                } out of the available ${maxLengthHelper}.`
          }
        >
          {valLength}&nbsp;/&nbsp;
          {maxLengthHelper}
        </div>
      )}
    </Wrapper>
  );
};
