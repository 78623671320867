/* eslint-disable mastery/tabindex */
/* eslint-disable mastery/known-imports */
import {
  FullStoryElementType,
  getFsComponentPropsUtil,
} from '@utils/fullstory';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { KeyboardEvent, PureComponent, ReactNode } from 'react';
import { Button } from './button';
import { NativeCheckbox } from './nativeCheckbox';
import { Icons as IconsShape } from './shapes/iconsShape';
import { LanguageShape } from './shapes/languageShape';

interface TreeNodeProps {
  checked: number;
  disabled: boolean;
  expandDisabled: boolean;
  expanded: boolean;
  icons: IconsShape;
  isLeaf: boolean;
  isParent: boolean;
  label: ReactNode;
  lang: LanguageShape;
  optimisticToggle: boolean;
  showNodeIcon: boolean;
  treeId: string;
  value: string | number;
  onCheck: (args: { value: string | number; checked: boolean }) => void;
  onExpand: (args: { value: string | number; expanded: boolean }) => void;

  children?: ReactNode;
  className?: string;
  expandOnClick?: boolean;
  icon?: ReactNode;
  showCheckbox?: boolean;
  title?: string;
  onClick?: (args: { value: string | number; checked: boolean }) => void;

  fsType?: string;
  fsName?: string;
  fsParent?: string;
  fsElement?: FullStoryElementType;
  fsCheckboxElement?: FullStoryElementType;
  fsItemElement?: FullStoryElementType;
}

export class TreeNode extends PureComponent<TreeNodeProps> {
  static defaultProps = {
    children: null,
    className: null,
    expandOnClick: false,
    icon: null,
    showCheckbox: true,
    title: null,
    onClick: noop,
  };

  constructor(props: TreeNodeProps) {
    super(props);

    this.onCheck = this.onCheck.bind(this);
    this.onCheckboxKeyPress = this.onCheckboxKeyPress.bind(this);
    this.onCheckboxKeyUp = this.onCheckboxKeyUp.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onExpand = this.onExpand.bind(this);
  }

  onCheck(): void {
    const { value, onCheck } = this.props;

    onCheck?.({ value, checked: this.getCheckState({ toggle: true }) });
  }

  onCheckboxKeyPress(event: KeyboardEvent): void {
    const { which } = event;

    // Prevent browser scroll when pressing space on the checkbox
    if (which === 32) {
      event.preventDefault();
    }
  }

  onCheckboxKeyUp(event: KeyboardEvent): void {
    const { keyCode } = event;

    if ([13, 32].includes(keyCode)) {
      this.onCheck();
    }
  }

  onClick(): void {
    const { expandOnClick, isParent, value, onClick } = this.props;

    // Auto expand if enabled
    if (isParent && expandOnClick) {
      this.onExpand();
    }

    onClick?.({ value, checked: this.getCheckState({ toggle: false }) });
  }

  onExpand(): void {
    const { expanded, value, onExpand } = this.props;

    onExpand({ value, expanded: !expanded });
  }

  getCheckState({ toggle }: { toggle: boolean }): boolean {
    const { checked, optimisticToggle } = this.props;

    // Toggle off state to checked
    if (checked === 0 && toggle) {
      return true;
    }

    // Node is already checked and we are not toggling
    if (checked === 1 && !toggle) {
      return true;
    }

    // Get/toggle partial state based on cascade model
    if (checked === 2) {
      return optimisticToggle;
    }

    return false;
  }

  renderCollapseButton(): ReactNode {
    const { expandDisabled, isLeaf, lang } = this.props;

    if (isLeaf) {
      return (
        <span className="rct-collapse">
          <span className="rct-icon" />
        </span>
      );
    }

    return (
      <Button
        className="rct-collapse rct-collapse-btn"
        disabled={expandDisabled}
        title={lang.toggle}
        onClick={this.onExpand}
      >
        {this.renderCollapseIcon()}
      </Button>
    );
  }

  renderCollapseIcon(): ReactNode {
    const {
      expanded,
      icons: { expandClose, expandOpen },
    } = this.props;

    if (!expanded) {
      return expandClose;
    }

    return expandOpen;
  }

  renderCheckboxIcon(): ReactNode {
    const {
      checked,
      icons: { uncheck, check, halfCheck },
    } = this.props;

    if (checked === 0) {
      return uncheck;
    }

    if (checked === 1) {
      return check;
    }

    return halfCheck;
  }

  renderNodeIcon(): ReactNode {
    const {
      expanded,
      icon,
      icons: { leaf, parentClose, parentOpen },
      isLeaf,
    } = this.props;

    if (icon !== null) {
      return icon;
    }

    if (isLeaf) {
      return leaf;
    }

    if (!expanded) {
      return parentClose;
    }

    return parentOpen;
  }

  renderBareLabel(children: ReactNode): ReactNode {
    const { onClick, title } = this.props;
    const clickable = onClick !== null;

    return (
      <span className="rct-bare-label" title={title}>
        {clickable ? (
          <span
            className="rct-node-clickable"
            onClick={this.onClick}
            onKeyPress={this.onClick}
            role="button"
            tabIndex={0}
          >
            {children}
          </span>
        ) : (
          children
        )}
      </span>
    );
  }

  renderCheckboxLabel(children: ReactNode): ReactNode {
    const { checked, disabled, title, treeId, value, onClick } = this.props;
    const clickable = onClick !== null;
    const inputId = `${treeId}-${String(value).split(' ').join('_')}`;

    const render = [
      <label key={0} htmlFor={inputId} title={title}>
        <NativeCheckbox
          checked={checked === 1}
          disabled={disabled}
          id={inputId}
          indeterminate={checked === 2}
          onClick={this.onCheck}
          onChange={() => {}}
        />
        <span
          aria-checked={checked === 1}
          aria-disabled={disabled}
          className="rct-checkbox"
          role="checkbox"
          tabIndex={0}
          onKeyPress={this.onCheckboxKeyPress}
          onKeyUp={this.onCheckboxKeyUp}
        >
          {this.renderCheckboxIcon()}
        </span>
        {/* original code - unknown why it is written like this */}
        {/* {!clickable ? children : null} */}
        {clickable ? children : null}
      </label>,
    ];

    // original code - unknown why we would want to use this
    // if (clickable) {
    //   render.push(
    //     <span
    //       key={1}
    //       className="rct-node-clickable"
    //       onClick={this.onClick}
    //       onKeyPress={this.onClick}
    //       role="link"
    //       tabIndex={0}
    //     >
    //       {children}
    //     </span>
    //   );
    // }

    return render;
  }

  renderLabel(): ReactNode {
    const {
      label,
      showCheckbox,
      showNodeIcon,
      fsName,
      fsItemElement,
      fsType,
      fsParent,
    } = this.props;
    const labelChildren = [
      showNodeIcon ? (
        <span key={0} className="rct-node-icon">
          {this.renderNodeIcon()}
        </span>
      ) : null,
      <span
        key={1}
        className="rct-title"
        {...getFsComponentPropsUtil({
          parent: fsParent,
          element: fsItemElement,
          type: fsType,
          name: fsName,
        })}
      >
        {label}
      </span>,
    ];

    if (!showCheckbox) {
      return this.renderBareLabel(labelChildren);
    }

    return this.renderCheckboxLabel(labelChildren);
  }

  renderChildren(): ReactNode {
    if (!this.props.expanded) {
      return null;
    }

    return this.props.children;
  }

  render(): ReactNode {
    const { className, disabled, expanded, isLeaf } = this.props;
    const nodeClass = classNames(
      {
        'rct-node': true,
        'rct-node-leaf': isLeaf,
        'rct-node-parent': !isLeaf,
        'rct-node-expanded': !isLeaf && expanded,
        'rct-node-collapsed': !isLeaf && !expanded,
        'rct-disabled': disabled,
      },
      className
    );

    return (
      <li className={nodeClass}>
        <span className="rct-text">
          {this.renderCollapseButton()}
          {this.renderLabel()}
        </span>
        {this.renderChildren()}
      </li>
    );
  }
}
