import { Grid } from '@components/Grid';
import { Icon } from '@components/Icon';
import { Text } from '@components/Text';
import { useFullstoryElement } from '@hooks/useFullstory';
import { usePropState } from '@hooks/usePropState';
import { useTheme } from '@hooks/useTheme';
import { FS_UNMASK, FullStoryElements } from '@utils/fullstory';
import { CARD_TITLE } from '@utils/zIndex';
import cx from 'classnames';
import { isFunction, isUndefined } from 'lodash-es';
import { FC, HTMLProps, ReactNode, forwardRef } from 'react';

export interface Props extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  title?: ReactNode | ((p: Omit<Props, 'title' | 'children'>) => ReactNode);
  /** Alternate treatment sometimes found in specific designs */
  flat?: boolean;
  /** Control prop for expanded state. If this prop is `true` or `false`, canExpand prop is unnecessary. */
  expanded?: boolean;
  /** Allow expand functionality */
  canExpand?: boolean;
  /** The text that is clickable for expand functionality */
  expandButtonText?: string;
  /** By default if the card is collapsed, the content of the card will not be rendered for performance reasons (`no-render`). If you need to continually render the content (ex to keep state) - use `render` */
  collapseStrategy?: 'no-render' | 'render';
  onExpandToggle?: (expanded: boolean) => void;
  hideExpandButton?: boolean;
}

const CARD_PADDING_TB = 7;
export const CARD_PADDING_LR = 16;

export const base = { padding: `${CARD_PADDING_TB}px ${CARD_PADDING_LR}px` };

export const CARD_TITLE_FONT_SIZE = 14;
export const CARD_TITLE_MIN_HEIGHT = 32;
export const CARD_EXPAND_BUTTON_SELECTOR = 'data-card-expand-btn';

export const CardTitle: FC<{
  expanded?: boolean;
  onExpandClick?: () => void;
  expandButtonText?: string;
  hideExpandButton?: boolean;
  className?: string;
}> = ({
  children,
  expanded,
  onExpandClick,
  expandButtonText,
  hideExpandButton,
  ...rest
}) => {
  const { hr } = useTheme();
  const { getFsComponentProps } = useFullstoryElement();
  const showExpandButton = !isUndefined(expanded) && hideExpandButton !== true;
  return (
    <Grid
      xs={showExpandButton ? 'min-content 1fr' : '1fr'}
      gap={expandButtonText ? 0.75 : 0}
      css={{
        ...base,
        borderBottom: `1px solid ${
          expanded === false ? 'transparent' : hr.background
        }`,
        fontSize: CARD_TITLE_FONT_SIZE,
        fontWeight: 500,
        position: 'relative',
        zIndex: CARD_TITLE,
        minHeight: CARD_TITLE_MIN_HEIGHT,
        lineHeight: '1.2em',
      }}
      data-card-title
      {...rest}
      className={cx(rest.className, FS_UNMASK)}
    >
      {showExpandButton && (
        <button
          type="button"
          {...{ [CARD_EXPAND_BUTTON_SELECTOR]: true }}
          onClick={(): void => onExpandClick?.()}
          css={{
            minWidth: 32,
            height: `calc(100% + ${CARD_PADDING_TB * 2}px)`,
            marginLeft: CARD_PADDING_LR * -1,
            marginTop: CARD_PADDING_TB * -1,
            display: 'grid',
            gridTemplateColumns: 'min-content 1fr',
            alignItems: 'center',
            gap: 10,
            paddingLeft: Math.round(CARD_PADDING_LR * 0.8),
          }}
          aria-label={(expanded ? 'Close ' : 'Open ') + 'the drawer'}
          {...getFsComponentProps({
            name: 'card-expand-button',
            element: FullStoryElements.BUTTON,
            parent: 'card',
            type: 'icon',
          })}
        >
          <Icon
            i={expanded ? 'angleUp' : 'angleDown'}
            color="primary"
            size="md"
            css={{ marginTop: expanded ? -2 : 0 }}
          />
          <Text
            primary
            css={{
              whiteSpace: 'nowrap',
              fontSize: CARD_TITLE_FONT_SIZE,
              lineHeight: '1.2em',
            }}
          >
            {expandButtonText}
          </Text>
        </button>
      )}
      {isFunction(children) ? children(rest) : children}
    </Grid>
  );
};

export const CardBody = forwardRef<
  HTMLDivElement,
  { children: ReactNode; onScroll?: (evt: fixMe) => void }
>(({ children, ...rest }, ref) => (
  <div css={base} data-card-body {...rest} ref={ref}>
    {children}
  </div>
));

export const CardWrapper: FC<{ flat?: boolean }> = ({
  children,
  flat,
  ...rest
}) => {
  const { card, box } = useTheme();
  return (
    <div
      data-card-wrapper
      css={{
        ...card,
        border: '1px solid transparent',
        ...(flat && {
          boxShadow: 'none',
          border: `1px solid ${box.borderColor}`,
        }),
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export const Card: FC<Props> = ({
  flat,
  title,
  children,
  expanded,
  canExpand,
  collapseStrategy = 'no-render',
  onExpandToggle,
  ...rest
}) => {
  const [isExpanded, setExpanded] = usePropState(
    expanded,
    isUndefined(canExpand) ? undefined : canExpand
  );
  return (
    <CardWrapper
      css={{
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateRows: 'min-content 1fr',
      }}
      flat={flat}
      {...rest}
    >
      {title && (
        <CardTitle
          {...rest}
          expanded={isExpanded}
          onExpandClick={(): void =>
            setExpanded((t) => {
              const newVal = !t;
              onExpandToggle?.(newVal);
              return newVal;
            })
          }
        >
          {title}
        </CardTitle>
      )}
      {(isExpanded !== false || collapseStrategy === 'render') && (
        <CardBody
          css={{
            display:
              collapseStrategy === 'render'
                ? isExpanded === false
                  ? 'none'
                  : 'block'
                : 'block',
          }}
        >
          {children}
        </CardBody>
      )}
    </CardWrapper>
  );
};
